<template>
  <div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <h3 class="iv2-news-detail__title text-left">{{ news.title }}</h3>
          <hr />
          <div class="iv2-news-detail__subtitle">
            <p v-if="news.opened_at">{{ news.opened_at }}</p>
            <p class="iv2-news-detail__label mx-0">{{ news.category_name }}</p>
          </div>
          <div class="spacer height-40"></div>
        </div>
      </div>
    </div>

    <div class="container-fluid" v-html="news.content"></div>

    <div class="container-fluid">
      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-40"></div>
          <div class="text-center">
            <span>
              <button
                @click="prevNews"
                class="iv2-news-detail__btn theme__main-btn"
              >
                {{ $store.state.publicLang.news.list_button }}
              </button>
            </span>
          </div>
          <div class="spacer height-100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news: Object,
    categoryId: String,
    page: String,
  },

  methods: {
    prevNews() {
      if (this.page || this.categoryId) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: "news",
          query: Object.assign({}, this.$route.query),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-news-detail__subtitle {
  display: flex;
  justify-content: space-between;

  .iv2-news-detail__label {
    padding: 0 20px;
    margin: 15px 20px;
    border: 1px solid;
    border-radius: 30px;
  }
}

.iv2-news-detail__btn {
  border: 0;
  letter-spacing: 2px;
  width: 300px;
  max-width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-news-detail__title {
    font-size: 1.5rem;
  }
}
/* SP Only */
@media (max-width: 767px) {
  .iv2-news-detail__title {
    font-size: 1.3rem;
  }
}
</style>
